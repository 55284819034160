import React, { useEffect, useState } from "react";
import "./Chat.scss";
import { Card, Col, Row } from "antd";
import { useAppDataContext } from "../../../data/context/AppDataContext";

interface IGreeting {
  onAutoResponse: (text: string) => void;
}

const ChatGPTGreeting: React.FC<IGreeting> = ({  onAutoResponse }) => {
  const [user, setUser] = useState<string>("");
  const {
    userData,
  } = useAppDataContext();
    React.useEffect(() => {
      if(userData.person && userData.person.firstName){
        setUser(userData.person.firstName + " " + userData.person.lastName) 
      } 
    }, [userData]);
  return (
    <>
      <h2 className="sai-greeting">
        Hi there, {user}. What would you like to do today?
      </h2>
     
    </>
  );
};

export default ChatGPTGreeting;
