/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import "./Chat.scss";
import { Button, Col, DatePicker, Row, Space } from "antd";
import ChatGPTSpeechBubbleAvatar from "./ChatGPTSpeechBubbleAvatar";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
interface ISpeechBubble {
  content: string;
  isLocal: boolean;
  onAutoResponse: (text: string) => void;
}

const ChatGPTSpeechBubble: React.FC<ISpeechBubble> = ({
  isLocal,
  content,
  onAutoResponse,
}) => {
  const [fromMe, setFormMe] = useState<boolean>(true);

  useEffect(() => {
    setFormMe(isLocal);
    return () => {
      setFormMe(false);
    };
  }, [isLocal]);

  useEffect(() => {

  }, [content]);

  const generateArticleClassName = useMemo((): string => {
    return `from-${fromMe ? "me" : "them"}`;
  }, [fromMe]);

  const MarkdownRenderer = ({ content }: { content: string }) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]} // Enable GitHub Flavored Markdown (e.g., tables)
        components={{
          // Customize rendering for specific elements (optional)
          table: ({ node, ...props }) => (
            <div className="table-container">
              <table className="grid-table" {...props} />
            </div>
          ),
          th: ({ node, ...props }) => (
            <th className="border border-gray-300 bg-gray-100 p-2" {...props} />
          ),
          td: ({ node, ...props }) => (
            <td className="border border-gray-300 p-2" {...props} />
          ),
          h3: ({ node, ...props }) => (
            <h3 className="text-xl font-bold mt-4 mb-2" {...props} />
          ),
          ul: ({ node, ...props }) => (
            <ul className="list-disc pl-6 mb-4" {...props} />
          ),
          li: ({ node, ...props }) => (
            <li className="mb-1" {...props} />
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    );
  };
  return (
    <>
      <div className={` sai-bubble-wrapper ${isLocal ? "out" : "in"}`}>
        {!fromMe && <ChatGPTSpeechBubbleAvatar />}
        <article className={generateArticleClassName}>
          <MarkdownRenderer content={content} />
        </article>
      </div>

    </>
  );
};

export default ChatGPTSpeechBubble;
