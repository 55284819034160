import React from "react";
import "./Chat.scss";
import { MdAutoAwesome } from "react-icons/md";

const ChatGPTSpeechBubbleAvatar: React.FC = () => {

  return (
    <>
      <div className="sai-avatar-grey">
        <MdAutoAwesome color='#ffffff' size={18} />
      </div>
    </>
  );
};

export default ChatGPTSpeechBubbleAvatar;
